// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy

/**
 * @fileoverview Templates in namespace ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$UIUtilsTemplate from './../../../../commons/UIUtilsTemplate.soy.generated.js';


/**
 * @param {!$parameterTable.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $parameterTable = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $parameterTable$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.parameterNames, opt_data.parameterDescriptions);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<string>} parameterNames
 * @param {!Array<?>} parameterDescriptions
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $parameterTable$ = function($$areYouAnInternalCaller, $ijData, parameterNames, parameterDescriptions) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.parameterTable']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.parameterTable']({parameterNames: parameterNames, parameterDescriptions: parameterDescriptions}, $ijData);
  }
  soy.assertParamType(Array.isArray(parameterNames), 'parameterNames', parameterNames, '@param', '!Array<string>');
  soy.assertParamType(Array.isArray(parameterDescriptions), 'parameterDescriptions', parameterDescriptions, '@param', '!Array<?>');
  let $output = '<div style="max-height: 500px; height: calc(95vh - 134px) !important; overflow-y: auto;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.parameterTable ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:27"' : '') + '><table class="widget-parameter-table" border="0" cellpadding="10">';
  const parameterName11714List = parameterNames;
  const parameterName11714ListLen = parameterName11714List.length;
  for (let parameterName11714Index = 0; parameterName11714Index < parameterName11714ListLen; parameterName11714Index++) {
    const parameterName11714Data = parameterName11714List[parameterName11714Index];
    $output += '<tr id="parameter-error-' + soy.$$escapeHtmlAttribute(parameterName11714Index) + '" class="ui error message" style="display: none"><td></td><td id="parameter-error-text-' + soy.$$escapeHtmlAttribute(parameterName11714Index) + '"></td></tr><tr id="parameter-' + soy.$$escapeHtmlAttribute(parameterName11714Index) + '"><td class="parameter-name" style="text-align: right;"><abbr' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + parameterDescriptions[/** @type {?} */ (parameterName11714Index)])) + '>' + soy.$$escapeHtml(parameterName11714Data) + '</abbr><br></td><td class="parameter-container-marker"></td></tr>';
  }
  $output += '</table></div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $parameterTable as parameterTable };
export { $parameterTable$ as parameterTable$ };
/**
 * @typedef {{
 *  parameterNames: !Array<string>,
 *  parameterDescriptions: !Array<?>,
 * }}
 */
$parameterTable.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $parameterTable.soyTemplateName = 'ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.parameterTable';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $markupFormattingHelpLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.markupFormattingHelpLink']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.markupFormattingHelpLink'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<small' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.markupFormattingHelpLink ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:54"' : '') + '><a id="show-markup-formatting-help" style="cursor: pointer;"><i class="help circle icon"></i>Formatting</a></small>');
};
export { $markupFormattingHelpLink as markupFormattingHelpLink };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $markupFormattingHelpLink.soyTemplateName = 'ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.markupFormattingHelpLink';
}


/**
 * @param {!$inputText.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $inputText = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $inputText$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.currentValue);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} currentValue
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $inputText$ = function($$areYouAnInternalCaller, $ijData, currentValue) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputText']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputText']({currentValue: currentValue}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui input"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputText ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:66"' : '') + '><input class="text-input" type="text" style="width: 300px" value="' + soy.$$escapeHtmlAttribute(currentValue) + '"></div>');
};
export { $inputText as inputText };
export { $inputText$ as inputText$ };
/**
 * @typedef {{
 *  currentValue: ?,
 * }}
 */
$inputText.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $inputText.soyTemplateName = 'ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputText';
}


/**
 * @param {!$multilineInput.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $multilineInput = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $multilineInput$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.currentValue);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} currentValue
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $multilineInput$ = function($$areYouAnInternalCaller, $ijData, currentValue) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.multilineInput']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.multilineInput']({currentValue: currentValue}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui form"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.multilineInput ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:77"' : '') + '><div class="field"><textarea class="text-input" style="width: 300px; margin-bottom: 0px" rows="5" type="text">' + soy.$$escapeHtmlRcdata(currentValue) + '</textarea></div></div>');
};
export { $multilineInput as multilineInput };
export { $multilineInput$ as multilineInput$ };
/**
 * @typedef {{
 *  currentValue: ?,
 * }}
 */
$multilineInput.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $multilineInput.soyTemplateName = 'ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.multilineInput';
}


/**
 * @param {?$inputCheckbox.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $inputCheckbox = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $inputCheckbox$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.checked, opt_data.id, opt_data.value, opt_data.title, opt_data.additionalClasses, opt_data.disabled);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {boolean|null|undefined=} checked
 * @param {null|string|undefined=} id
 * @param {null|string|undefined=} value
 * @param {null|string|undefined=} title
 * @param {?=} additionalClasses
 * @param {?=} disabled
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $inputCheckbox$ = function($$areYouAnInternalCaller, $ijData, checked, id, value, title, additionalClasses, disabled) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputCheckbox']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputCheckbox']({checked: checked, id: id, value: value, title: title, additionalClasses: additionalClasses, disabled: disabled}, $ijData);
  }
  soy.assertParamType(checked == null || typeof checked === 'boolean', 'checked', checked, '@param', 'boolean|null|undefined');
  soy.assertParamType(id == null || typeof id === 'string', 'id', id, '@param', 'null|string|undefined');
  soy.assertParamType(value == null || typeof value === 'string', 'value', value, '@param', 'null|string|undefined');
  soy.assertParamType(title == null || typeof title === 'string', 'title', title, '@param', 'null|string|undefined');
  let $output = '<div class="ui checkbox"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputCheckbox ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:100"' : '') + '><input' + (id ? ' id="' + soy.$$escapeHtmlAttribute(id) + '"' : '') + ' type="checkbox"' + (title ? ' id="checkbox-' + soy.$$escapeHtmlAttribute(title) + '"' : '') + ' class="checkbox';
  if (additionalClasses) {
    const class11913List = additionalClasses;
    const class11913ListLen = class11913List.length;
    for (let class11913Index = 0; class11913Index < class11913ListLen; class11913Index++) {
      const class11913Data = class11913List[class11913Index];
      $output += ' ' + soy.$$escapeHtmlAttribute(class11913Data);
    }
  }
  $output += '"' + (value ? ' value="' + soy.$$escapeHtmlAttribute(value) + '"' : '') + (checked ? ' checked="checked"' : '') + (disabled ? ' disabled' : '') + '><label' + (title ? ' for="checkbox-' + soy.$$escapeHtmlAttribute(title) + '"' : '') + '>' + (title ? soy.$$escapeHtml(title) : '') + '</label></div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $inputCheckbox as inputCheckbox };
export { $inputCheckbox$ as inputCheckbox$ };
/**
 * @typedef {{
 *  checked?: (boolean|null|undefined),
 *  id?: (null|string|undefined),
 *  value?: (null|string|undefined),
 *  title?: (null|string|undefined),
 *  additionalClasses?: (?|undefined),
 *  disabled?: (?|undefined),
 * }}
 */
$inputCheckbox.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $inputCheckbox.soyTemplateName = 'ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputCheckbox';
}


/**
 * @param {!$inputDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $inputDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $inputDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.currentValue, opt_data.opt_buttonClass, opt_data.addRemoveButton);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} currentValue
 * @param {?=} opt_buttonClass
 * @param {boolean|null|undefined=} addRemoveButton
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $inputDialog$ = function($$areYouAnInternalCaller, $ijData, currentValue, opt_buttonClass, addRemoveButton) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputDialog']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputDialog']({currentValue: currentValue, opt_buttonClass: opt_buttonClass, addRemoveButton: addRemoveButton}, $ijData);
  }
  soy.assertParamType(addRemoveButton == null || typeof addRemoveButton === 'boolean', 'addRemoveButton', addRemoveButton, '@param', 'boolean|null|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="input-append" style="padding-bottom: 5px"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputDialog ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:120"' : '') + '><div class="ui fluid action input"><input type="text" disabled="disabled" class="value-marker" style="width: 263px;" value="' + soy.$$escapeHtmlAttribute(currentValue) + '"><button type="button" class="compact ui button ' + (opt_buttonClass ? soy.$$escapeHtmlAttribute(opt_buttonClass) : '') + '">...</button>' + (addRemoveButton ? '<div class="ui button icon remove-button"><i class="icon trash"></i></div>' : '') + '</div></div>');
};
export { $inputDialog as inputDialog };
export { $inputDialog$ as inputDialog$ };
/**
 * @typedef {{
 *  currentValue: ?,
 *  opt_buttonClass?: (?|undefined),
 *  addRemoveButton?: (boolean|null|undefined),
 * }}
 */
$inputDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $inputDialog.soyTemplateName = 'ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputDialog';
}


/**
 * @param {!$inputForPathsDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @private
 */
const $inputForPathsDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $inputForPathsDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.currentValue, opt_data.showPathOverrideField, opt_data.showMetricSelectionField, opt_data.overrideFieldValue);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} currentValue
 * @param {boolean|null|undefined=} showPathOverrideField
 * @param {boolean|null|undefined=} showMetricSelectionField
 * @param {null|string|undefined=} overrideFieldValue
 * @return {!goog_soy_data.SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $inputForPathsDialog$ = function($$areYouAnInternalCaller, $ijData, currentValue, showPathOverrideField, showMetricSelectionField, overrideFieldValue) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputForPathsDialog']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputForPathsDialog']({currentValue: currentValue, showPathOverrideField: showPathOverrideField, showMetricSelectionField: showMetricSelectionField, overrideFieldValue: overrideFieldValue}, $ijData);
  }
  soy.assertParamType(showPathOverrideField == null || typeof showPathOverrideField === 'boolean', 'showPathOverrideField', showPathOverrideField, '@param', 'boolean|null|undefined');
  soy.assertParamType(showMetricSelectionField == null || typeof showMetricSelectionField === 'boolean', 'showMetricSelectionField', showMetricSelectionField, '@param', 'boolean|null|undefined');
  soy.assertParamType(overrideFieldValue == null || typeof overrideFieldValue === 'string', 'overrideFieldValue', overrideFieldValue, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="input-append" style="margin-bottom: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(showPathOverrideField ? '24px' : '10px')) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputForPathsDialog ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:141"' : '') + '><div class="ui form"><div class="field"><label>Path</label><div class="ui fluid action input"><input type="text" disabled="disabled" class="value - marker" style="width: 263px;" value="' + soy.$$escapeHtmlAttribute(currentValue.path.path) + '"><button type="button" class="compact ui button dialog-button">...</button><div class="ui button icon remove-button"><i class="icon trash"></i></div></div></div>' + (showPathOverrideField ? '<div class="field"><label>... show as (optional)</label><div class="ui small fluid input"><input type="text" placeholder="e.g. \'UI tests\'" value="' + soy.$$escapeHtmlAttribute(overrideFieldValue != null ? overrideFieldValue : '') + '" data-for-path="' + soy.$$escapeHtmlAttribute(currentValue.project + '/' + currentValue.path.path) + '"></div></div>' : '') + (showMetricSelectionField ? '<div class="field"><label>Metric</label><div class="ui fluid action input"><input type="text" disabled="disabled" class="metric-value" style="width: 263px;" value="' + soy.$$escapeHtmlAttribute(currentValue.metric) + '"><button type="button" class="compact ui button metric-button">...</button></div></div>' : '') + '</div></div>');
};
/**
 * @typedef {{
 *  currentValue: ?,
 *  showPathOverrideField?: (boolean|null|undefined),
 *  showMetricSelectionField?: (boolean|null|undefined),
 *  overrideFieldValue?: (null|string|undefined),
 * }}
 */
$inputForPathsDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $inputForPathsDialog.soyTemplateName = 'ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputForPathsDialog';
}


/**
 * @param {!$radioButtonGroup.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $radioButtonGroup = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $radioButtonGroup$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.allValues, opt_data.groupName, opt_data.checkedIndex);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<?>} allValues
 * @param {string} groupName
 * @param {number} checkedIndex
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $radioButtonGroup$ = function($$areYouAnInternalCaller, $ijData, allValues, groupName, checkedIndex) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.radioButtonGroup']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.radioButtonGroup']({allValues: allValues, groupName: groupName, checkedIndex: checkedIndex}, $ijData);
  }
  soy.assertParamType(Array.isArray(allValues), 'allValues', allValues, '@param', '!Array<?>');
  soy.assertParamType(typeof groupName === 'string', 'groupName', groupName, '@param', 'string');
  soy.assertParamType(typeof checkedIndex === 'number', 'checkedIndex', checkedIndex, '@param', 'number');
  let $output = '<div class="ui form"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.radioButtonGroup ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:193"' : '') + '><div class="grouped fields">';
  const value12232List = allValues;
  const value12232ListLen = value12232List.length;
  for (let value12232Index = 0; value12232Index < value12232ListLen; value12232Index++) {
    const value12232Data = value12232List[value12232Index];
    $output += '<div class="field">' + $radioButton$(soy.$$internalCallMarkerDoNotUse, $ijData, value12232Data, groupName, value12232Index == checkedIndex) + '</div>';
  }
  $output += '</div></div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $radioButtonGroup as radioButtonGroup };
export { $radioButtonGroup$ as radioButtonGroup$ };
/**
 * @typedef {{
 *  allValues: !Array<?>,
 *  groupName: string,
 *  checkedIndex: number,
 * }}
 */
$radioButtonGroup.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $radioButtonGroup.soyTemplateName = 'ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.radioButtonGroup';
}


/**
 * @param {!$radioButton.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @private
 */
const $radioButton = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $radioButton$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.value, opt_data.groupName, opt_data.checked, opt_data.id);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} value
 * @param {string} groupName
 * @param {boolean|null|undefined=} checked
 * @param {null|string|undefined=} id
 * @return {!goog_soy_data.SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $radioButton$ = function($$areYouAnInternalCaller, $ijData, value, groupName, checked, id) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.radioButton']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.radioButton']({value: value, groupName: groupName, checked: checked, id: id}, $ijData);
  }
  soy.assertParamType(typeof value === 'string', 'value', value, '@param', 'string');
  soy.assertParamType(typeof groupName === 'string', 'groupName', groupName, '@param', 'string');
  soy.assertParamType(checked == null || typeof checked === 'boolean', 'checked', checked, '@param', 'boolean|null|undefined');
  soy.assertParamType(id == null || typeof id === 'string', 'id', id, '@param', 'null|string|undefined');
  let $output = '<div class="ui checkbox radio"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.radioButton ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:222"' : '') + '>';
  const idOrValue__soy12257 = id != null ? id : value;
  $output += '<input type="radio" class="input-radio" name=' + soy.$$escapeHtmlAttributeNospace(groupName) + ' id="' + soy.$$escapeHtmlAttribute(idOrValue__soy12257) + '" value="' + soy.$$escapeHtmlAttribute(value) + '"' + (checked ? ' checked="checked"' : '') + '><label for="' + soy.$$escapeHtmlAttribute(idOrValue__soy12257) + '">' + soy.$$escapeHtml(value) + '</label></div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
/**
 * @typedef {{
 *  value: string,
 *  groupName: string,
 *  checked?: (boolean|null|undefined),
 *  id?: (null|string|undefined),
 * }}
 */
$radioButton.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $radioButton.soyTemplateName = 'ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.radioButton';
}


/**
 * @param {!$stringsList.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $stringsList = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $stringsList$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.currentValue);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<?>} currentValue
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $stringsList$ = function($$areYouAnInternalCaller, $ijData, currentValue) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.stringsList']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.stringsList']({currentValue: currentValue}, $ijData);
  }
  soy.assertParamType(Array.isArray(currentValue), 'currentValue', currentValue, '@param', '!Array<?>');
  let $output = '<div style="width: 300px"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.stringsList ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:238"' : '') + '>';
  const content12304List = currentValue;
  const content12304ListLen = content12304List.length;
  for (let content12304Index = 0; content12304Index < content12304ListLen; content12304Index++) {
    const content12304Data = content12304List[content12304Index];
    $output += $inputText$(soy.$$internalCallMarkerDoNotUse, $ijData, content12304Data);
  }
  $output += '<button class="compact ui button plus-button-marker" style="margin: 5px">+</button></div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $stringsList as stringsList };
export { $stringsList$ as stringsList$ };
/**
 * @typedef {{
 *  currentValue: !Array<?>,
 * }}
 */
$stringsList.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $stringsList.soyTemplateName = 'ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.stringsList';
}


/**
 * @param {!$selectionList.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $selectionList = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $selectionList$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.options, opt_data.selections);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<string>} options
 * @param {!Array<?>} selections
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $selectionList$ = function($$areYouAnInternalCaller, $ijData, options, selections) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.selectionList']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.selectionList']({options: options, selections: selections}, $ijData);
  }
  soy.assertParamType(Array.isArray(options), 'options', options, '@param', '!Array<string>');
  soy.assertParamType(Array.isArray(selections), 'selections', selections, '@param', '!Array<?>');
  let $output = '';
  const selection12324List = selections;
  const selection12324ListLen = selection12324List.length;
  for (let selection12324Index = 0; selection12324Index < selection12324ListLen; selection12324Index++) {
    const selection12324Data = selection12324List[selection12324Index];
    $output += '<div' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.selectionList ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:260"' : '') + '>' + $soy$ts$commons$UIUtilsTemplate.inputSelect$(soy.$$internalCallMarkerDoNotUse, $ijData, '', options, selection12324Data) + '<button class="delete-button ui compact icon button" style="vertical-align: middle; margin-left: 5px;"><i class="trash icon"></i></button></div>';
  }
  $output += selections.length < options.length ? '<button class="compact ui button plus-button-marker" style="margin: 5px"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.selectionList ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:271"' : '') + '>+</button>' : '';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $selectionList as selectionList };
export { $selectionList$ as selectionList$ };
/**
 * @typedef {{
 *  options: !Array<string>,
 *  selections: !Array<?>,
 * }}
 */
$selectionList.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $selectionList.soyTemplateName = 'ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.selectionList';
}


/**
 * @param {!$coverageSourcesList.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $coverageSourcesList = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $coverageSourcesList$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.coverageSources, opt_data.useAll);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<?>} coverageSources
 * @param {?} useAll
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $coverageSourcesList$ = function($$areYouAnInternalCaller, $ijData, coverageSources, useAll) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.coverageSourcesList']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.coverageSourcesList']({coverageSources: coverageSources, useAll: useAll}, $ijData);
  }
  soy.assertParamType(Array.isArray(coverageSources), 'coverageSources', coverageSources, '@param', '!Array<?>');
  let $output = '<div class="ui form"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.coverageSourcesList ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:287"' : '') + '><div class="grouped fields"><div class="field">' + $inputCheckbox$(soy.$$internalCallMarkerDoNotUse, $ijData, useAll, 'checkbox-select-all', 'all', '(All)', soy.$$makeArray('all')) + '</div>';
  const source12396List = coverageSources;
  const source12396ListLen = source12396List.length;
  if (source12396ListLen > 0) {
    for (let source12396Index = 0; source12396Index < source12396ListLen; source12396Index++) {
      const source12396Data = source12396List[source12396Index];
      $output += '<div class="field">' + $inputCheckbox$(soy.$$internalCallMarkerDoNotUse, $ijData, soy.$$coerceToBoolean(source12396Data.checked) || soy.$$coerceToBoolean(useAll), undefined, source12396Data.value, source12396Data.value, soy.$$makeArray('coverage-source-checkbox')) + '</div>';
    }
  } else {
    $output += '<div class="small basic ui label">Currently no test coverage sources found.</div>';
  }
  $output += '</div></div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $coverageSourcesList as coverageSourcesList };
export { $coverageSourcesList$ as coverageSourcesList$ };
/**
 * @typedef {{
 *  coverageSources: !Array<?>,
 *  useAll: ?,
 * }}
 */
$coverageSourcesList.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $coverageSourcesList.soyTemplateName = 'ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.coverageSourcesList';
}


/**
 * @param {!$inputDialogList.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $inputDialogList = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $inputDialogList$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.values, opt_data.showPathOverrideField, opt_data.showMetricSelectionField);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} values
 * @param {boolean} showPathOverrideField
 * @param {boolean} showMetricSelectionField
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $inputDialogList$ = function($$areYouAnInternalCaller, $ijData, values, showPathOverrideField, showMetricSelectionField) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputDialogList']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputDialogList']({values: values, showPathOverrideField: showPathOverrideField, showMetricSelectionField: showMetricSelectionField}, $ijData);
  }
  soy.assertParamType(typeof showPathOverrideField === 'boolean', 'showPathOverrideField', showPathOverrideField, '@param', 'boolean');
  soy.assertParamType(typeof showMetricSelectionField === 'boolean', 'showMetricSelectionField', showMetricSelectionField, '@param', 'boolean');
  let $output = '';
  const value12426List = values;
  const value12426ListLen = value12426List.length;
  for (let value12426Index = 0; value12426Index < value12426ListLen; value12426Index++) {
    const value12426Data = value12426List[value12426Index];
    $output += '<div' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputDialogList ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:325"' : '') + '>' + $inputForPathsDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, value12426Data, showPathOverrideField, showMetricSelectionField, value12426Data.uiLabel) + '</div>';
  }
  $output += '<button class="ui button plus-button"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputDialogList ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:334"' : '') + '>+</button>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $inputDialogList as inputDialogList };
export { $inputDialogList$ as inputDialogList$ };
/**
 * @typedef {{
 *  values: ?,
 *  showPathOverrideField: boolean,
 *  showMetricSelectionField: boolean,
 * }}
 */
$inputDialogList.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $inputDialogList.soyTemplateName = 'ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.inputDialogList';
}


/**
 * @param {!$compactError.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $compactError = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $compactError$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.message);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} message
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $compactError$ = function($$areYouAnInternalCaller, $ijData, message) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.compactError']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.compactError']({message: message}, $ijData);
  }
  soy.assertParamType(typeof message === 'string', 'message', message, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="error-compact"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.compactError ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:344"' : '') + '>' + soy.$$escapeHtml(message) + '</div>');
};
export { $compactError as compactError };
export { $compactError$ as compactError$ };
/**
 * @typedef {{
 *  message: string,
 * }}
 */
$compactError.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $compactError.soyTemplateName = 'ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.compactError';
}


/**
 * @param {!$optionSelector.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $optionSelector = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $optionSelector$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.options, opt_data.selectorId, opt_data.selectedOption, opt_data.emptyOptionValue);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<{value: string, text: string,}>} options
 * @param {string} selectorId
 * @param {null|string|undefined=} selectedOption
 * @param {null|string|undefined=} emptyOptionValue
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $optionSelector$ = function($$areYouAnInternalCaller, $ijData, options, selectorId, selectedOption, emptyOptionValue) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.optionSelector']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.optionSelector']({options: options, selectorId: selectorId, selectedOption: selectedOption, emptyOptionValue: emptyOptionValue}, $ijData);
  }
  soy.assertParamType(Array.isArray(options), 'options', options, '@param', '!Array<{value: string, text: string,}>');
  soy.assertParamType(typeof selectorId === 'string', 'selectorId', selectorId, '@param', 'string');
  soy.assertParamType(selectedOption == null || typeof selectedOption === 'string', 'selectedOption', selectedOption, '@param', 'null|string|undefined');
  soy.assertParamType(emptyOptionValue == null || typeof emptyOptionValue === 'string', 'emptyOptionValue', emptyOptionValue, '@param', 'null|string|undefined');
  let $output = '<div id="selector-container" class="right item"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.optionSelector ts/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy:360"' : '') + '><select id="' + soy.$$escapeHtmlAttribute(selectorId) + '" class="ui selection search dropdown dropdown-widget-parameter">' + (emptyOptionValue ? '<option value="' + soy.$$escapeHtmlAttribute(emptyOptionValue) + '">' + soy.$$escapeHtml(emptyOptionValue) + '</option> ' : '');
  const option12489List = options;
  const option12489ListLen = option12489List.length;
  for (let option12489Index = 0; option12489Index < option12489ListLen; option12489Index++) {
    const option12489Data = option12489List[option12489Index];
    $output += '<option value="' + soy.$$escapeHtmlAttribute(option12489Data.value) + '"' + (soy.$$equals(selectedOption, option12489Data.value) ? ' selected' : '') + '>' + soy.$$escapeHtml(option12489Data.text) + '</option>';
  }
  $output += '</select></div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $optionSelector as optionSelector };
export { $optionSelector$ as optionSelector$ };
/**
 * @typedef {{
 *  options: !Array<{value: string, text: string,}>,
 *  selectorId: string,
 *  selectedOption?: (null|string|undefined),
 *  emptyOptionValue?: (null|string|undefined),
 * }}
 */
$optionSelector.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $optionSelector.soyTemplateName = 'ts.perspectives.dashboard.widgets.parameters.WidgetParameterTemplate.optionSelector';
}
